<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '600px' }"
    header="Fomulário de Usuários"
    :modal="true"
    @hide="hideDialog"
    class="p-fluid"
  >
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-12">
        <label for="nome">Nome</label>
        <InputText
          id="nome"
          v-model="v$.user.nome.$model"
          maxlength="255"
          placeholder="Digite o nome"
          :class="{
            'p-invalid': submitted && v$.user.nome.$invalid,
          }"
        />
        <small class="p-error" v-if="submitted && v$.user.nome.$invalid"
          >Nome é obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-12">
        <label for="contato">Contato</label>
        <InputMask
          id="contato"
          v-model="v$.user.contato.$model"
          placeholder="Digite o contato"
          mask="(99) 9 9999-9999"
          :class="{
            'p-invalid': submitted && v$.user.contato.$invalid,
          }"
        />
        <small class="p-error" v-if="submitted && v$.user.contato.$invalid"
          >Contato é obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-12">
        <label for="email">E-mail</label>
        <InputText
          id="email"
          v-model.trim="v$.user.email.$model"
          maxlength="255"
          placeholder="Digite o e-mail"
          :class="{ 'p-invalid': submitted && v$.user.email.$invalid }"
        />
        <small
          v-if="submitted && v$.user.email.email.$invalid"
          class="p-invalid"
          >Email inválido</small
        >
        <small class="p-error" v-else-if="submitted && v$.user.email.$invalid"
          >Email é obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-12" v-if="!user.id">
        <label for="password">Senha</label>
        <Password
          id="password"
          v-model.trim="v$.user.senha.$model"
          :toggleMask="true"
          placeholder="Digite sua senha"
          :class="{ 'p-invalid': submitted && !v$.user.senha.$model }"
        />
        <small v-if="submitted && !v$.user.senha.$model" class="p-error"
          >Senha é obrigatório.</small
        >
        <small
          v-else-if="submitted && v$.user.senha.minLength.$invalid"
          class="p-error"
          >A senha deve ter no mínimo 6 caracteres</small
        >
      </div>
      <div class="field col-12 md:col-12" v-if="!user.id">
        <label for="password">Confirmar senha</label>
        <Password
          id="password"
          v-model.trim="confirmPassword"
          :toggleMask="true"
          placeholder="Confirme sua senha"
          :class="{
            'p-invalid': submitted && confirmPassword !== user.senha,
          }"
        />
        <small
          class="p-error"
          v-if="submitted && confirmPassword !== user.senha"
          >As senhas devem ser iguais</small
        >
      </div>
      <div class="field col-12 md:col-12">
        <label for="group">Grupos</label>
        <Dropdown
          id="group"
          v-model="v$.user.grupo.$model"
          :options="groups"
          optionLabel="descricao"
          placeholder="Selecione um grupo"
          :class="{
            'p-invalid': submitted && v$.user.grupo.$invalid,
          }"
        />
        <small class="p-error" v-if="submitted && v$.user.grupo.$invalid"
          >Grupo é obrigatório.</small
        >
      </div>
      <div class="field col-12 md:col-12">
        <label for="group">Unidade/Estabelecimento</label>
        <MultiSelect
          id="group"
          display="chip"
          v-model="v$.user.estabelecimento.$model"
          :options="allEstabelecimento"
          optionLabel="cnes"
          :filter="true"
          placeholder="Selecione um Estabelecimento"
          :class="{
            'p-invalid': submitted && v$.user.estabelecimento.$invalid,
          }"
        >
        <template #option="slotProps">
            <small>
              <b>{{ slotProps.option.cnes }} </b> - {{ slotProps.option.nome }}
            </small>
            <br />
            <small>
              {{ slotProps.option.cnpj }}
            </small>
          </template>
        </MultiSelect>

        <!-- <Dropdown
          id="group"
          v-model="v$.user.estabelecimento.$model"
          :options="allEstabelecimento"
          optionLabel="cnes"
          placeholder="Selecione um Estabelecimento"
          :class="{
            'p-invalid': submitted && v$.user.grupo.$invalid,
          }"
        >
          <template #option="slotProps">
            <small>
              <b>{{ slotProps.option.cnes }} </b> - {{ slotProps.option.nome }}
            </small>
            <br />
            <small>
              {{ slotProps.option.cnpj }}
            </small>
          </template>
        </Dropdown>
        <small class="p-error" v-if="submitted && v$.user.grupo.$invalid"
          >Grupo é obrigatório.</small
        > -->
      </div>
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button"
        icon="pi pi-check"
        @click="send(!v$.user.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>
<script>
//Models
import Users from "../../../models/usuario";

//Services
import UsersService from "../../../service/user/user_service";
import GroupService from "../../../service/group/group_service";
import EstabelecimentoService from "../../../service/estabelecimento/estabelecimento_service";

//VALIDATIONS
import { useVuelidate } from "@vuelidate/core";

export default {
  props: ["userSelected"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      user: new Users(),
      submitted: false,
      confirmPassword: null,
      userService: new UsersService(),
      estabelecimentoService: new EstabelecimentoService(),
      allEstabelecimento: [],
      groupService: new GroupService(),
      groups: [],
    };
  },
  mounted() {
    this.findGroups();
    this.findAllEstabelecimento();
  },
  validations() {
    return {
      user: new Users().validations(),
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.$store.state.views.users.dialogForm;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        this.$store.state.views.users.dialogForm = value;
      },
    },
  },
  methods: {
    send(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        if (this.user.id) {
          this.update();
        } else {
          this.create();
        }
      } else {
        return;
      }
    },
    create() {
      this.userService
        .create(this.user)
        .then((data) => {
          if (data.status === 201) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro cadastrado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    update() {
      this.user.senha = "12345678910";
      this.userService
        .update(this.user)
        .then((data) => {
          if (data.status === 200) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registro alterado com sucesso.",
              life: 3000,
            });
            this.$emit("findAll");
            this.hideDialog();
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.user = new Users();
      this.confirmPassword = null;
      this.submitted = false;
      this.visibleDialog = false;
      this.$emit("findAll");
    },
    getData() {
      if (this.userSelected) {
        this.user = this.userSelected;
      }
    },
    findGroups() {
      this.groupService.findAll().then((data) => {
        this.groups = data;
      });
    },
    findAllEstabelecimento() {
      this.estabelecimentoService.findAll().then((data) => {
        this.allEstabelecimento = data;
      });
    },
  },
};
</script>
<style scoped></style>
