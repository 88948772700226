<template>
  <div class="p-col-12">
    <Panel header="Gerenciamento de Usuários">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <label for="nome">Nome</label>
          <InputText
            id="nome"
            v-model="lazyParams.nome"
            autofocus
            placeholder="Digite o nome do profissional"
            @keydown.enter="loadLazyData"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label for="nome">E-mail</label>
          <InputText
            id="nome"
            v-model="lazyParams.email"
            autofocus
            placeholder="Digite o nome do profissional"
            @keydown.enter="loadLazyData"
          />
        </div>
        <div class="field col-12 md:col-4">
          <label for="cnes">CNES (Estabelecimento)</label>
          <MultiSelect
            id="cnes"
            v-model="lazyParams.estabelecimentos"
            :options="estabelecimentos"
            optionLabel="cnes"
            optionValue="cnes"
            placeholder="Selecione um CNES (estabelecimento)."
            @change="loadLazyData"
            :filter="true"
            filterPlaceholder="Para buscar, digite o número do CNES"
          >
            <template #option="slotProps">
              <small>
                <b>{{ slotProps.option.cnes }} </b> -
                {{ slotProps.option.nome }}
              </small>
              <br />
              <small>{{ slotProps.option.cnpj }}</small>
            </template>
          </MultiSelect>
        </div>
      </div>

      <div class="flex justify-content-end flex-wrap mb-4">
        <Button
          label="Pesquisar"
          class="p-button-raised mr-2"
          icon="pi pi-search"
          @click="loadLazyData"
        />
        <Button
          label="Limpar"
          icon="pi pi-times"
          class="p-button-raised p-button-text"
          @click="cleanAll()"
        />
      </div>

      <Toolbar>
        <template #start>
          <Button
            label="Novo"
            icon="pi pi-plus"
            class="mr-2"
            @click="showCreate"
          />
        </template>
        <template #end>
          <Button
            :disabled="selectedUsuarios == 0"
            label="Ativar/Inativar Selecionados"
            class="p-button-success mr-2"
            @click="atualizarStatusEmLote()"
          />
          <Button
            label="Ativar/Inativar Todos"
            class="p-button-raised p-button-help mr-2"
            v-model="checked"
            @click="
              showConfirmAtualizarStatusUsuarios(this.$Token.jwtDecode().id)
            "
          />
        </template>
      </Toolbar>
      <DataTable
        :value="customers"
        :lazy="true"
        :paginator="true"
        :rows="10"
        ref="dt"
        dataKey="id"
        class="p-datatable-sm"
        :totalRecords="totalRecords"
        :loading="loading"
        @page="onPage($event)"
        @sort="onSort($event)"
        v-model:selection="selectedUsuarios"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} usuários."
      >
        <template #empty>
          <div class="text-center">Nenhum resultado encontrado...</div>
        </template>

        <Column selectionMode="multiple"></Column>

        <Column field="nome" header="Nome" ref="nome">
          <template #body="slotProps">
            {{ slotProps.data.nome }}
          </template>
        </Column>
        <Column field="contato" header="Contato">
          <template #body="slotProps">
            {{ slotProps.data.contato }}
          </template>
        </Column>
        <Column field="email" header="Email" ref="email">
          <template #body="slotProps">
            {{ slotProps.data.email }}
          </template>
        </Column>
        <Column field="status" header="Status" ref="status">
          <template #body="slotProps">
            <span class="p-column-title">Status:</span>
            <span
              v-if="slotProps.data.status === true"
              :class="'user-badge status-' + slotProps.data.status"
              @click="changeStatus(slotProps.data)"
              v-tooltip.top="'Clique para INATIVAR'"
              style="cursor: pointer"
              >Ativo</span
            >
            <span
              v-if="slotProps.data.status === false"
              :class="'user-badge status-' + slotProps.data.status"
              @click="changeStatus(slotProps.data)"
              v-tooltip.top="'CLIQUE ATIVAR'"
              style="cursor: pointer"
              >Inativo</span
            >
          </template>
        </Column>
        <Column field="desabilitado" header="Desabilitado" ref="desabilitado">
          <template #body="slotProps">
            <span
              v-if="slotProps.data.desabilitado === true"
              :class="'user-badge status-stop'"
              @click="desabilitarUsuario(slotProps.data)"
              v-tooltip.top="'Clique para INATIVAR'"
              style="cursor: pointer"
              >SIM</span
            >
            <span
              v-if="slotProps.data.desabilitado === false"
              :class="'user-badge status-info'"
              @click="desabilitarUsuario(slotProps.data)"
              v-tooltip.top="'CLIQUE ATIVAR'"
              style="cursor: pointer"
              >NÃO</span
            >
          </template>
        </Column>
        <Column field="grupo" header="Grupo de Permissões">
          <template #body="slotProps">
            {{ slotProps.data.grupo.descricao }}
          </template>
        </Column>
        <Column field="estabelecimento" header="Unidade">
          <template #body="slotProps">
            <div
              v-for="estabelecimento in slotProps.data.estabelecimento"
              :key="estabelecimento.cnes"
            >
              {{ estabelecimento.cnes }} | {{ estabelecimento.nome }}
            </div>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-success mr-2"
              @click="showUpdate(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA EDITAR'"
            />
            <Button
              icon="pi pi-lock"
              class="p-button-rounded p-button-info mr-2"
              @click="showResetPassword(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA RESETAR A SENHA'"
            />

            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-warning"
              @click="showRemove(slotProps.data)"
              v-tooltip.top="'CLIQUE PARA EXCLUIR'"
            />
          </template>
        </Column>
      </DataTable>
    </Panel>
    <dialog-form :userSelected="user" @findAll="loadLazyData" />
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>

<script>
//Models
import Users from "../../models/usuario";

//Services
import UsersService from "../../service/user/user_service";
import EstabelecimentoService from "../../service/estabelecimento/estabelecimento_service";

//Components
import DialogForm from "./components/dialog-form.vue";

export default {
  components: {
    DialogForm,
  },
  data() {
    return {
      userService: new UsersService(),
      estabelecimentoService: new EstabelecimentoService(),
      user: new Users(),
      checked: false,
      loading: false,
      totalRecords: 0,
      customers: null,
      estabelecimentos: null,
      selectedUsuarios: [],
      selectAll: false,
      // filters: {
      //   nome: { value: "", matchMode: "contains" },
      //   email: { value: "", matchMode: "contains" },
      // },
      lazyParams: {},
    };
  },
  mounted() {
    this.loading = true;
    this.lazyParams = {
      first: 0,
      rows: this.$refs.dt.rows,
      sortField: null,
      sortOrder: null,
      nome: "",
      email: "",
      estabelecimentos: [],
    };
    this.loadLazyData();
    this.buscarTodosEstabelecimentos();
  },
  methods: {
    showCreate() {
      this.user = new Users();
      this.$store.state.views.users.dialogForm = true;
    },
    showUpdate(user) {
      this.user = user;
      this.$store.state.views.users.dialogForm = true;
    },
    showResetPassword(user) {
      this.user = user;
      this.$confirm.require({
        header: "Deseja resetar a senha?",
        message: "Deseja resetar a senha do usuário?",
        icon: "pi pi-lock",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.userService
            .resetPassword(this.user)
            .then((data) => {
              this.$msgSuccess(data);
              this.loadLazyData();
            })
            .catch((error) => {
              this.$msgErro(error);
            });
        },
      });
    },
    showRemove(user) {
      this.$confirm.require({
        message: "Deseja Excluir esse registro?",
        header: "Deseja deletar?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.userService
            .delete(user.id)
            .then((data) => {
              this.$msgSuccess(data);
              this.loadLazyData();
            })
            .catch((error) => {
              this.$msgErro(error);
            });
        },
      });
    },
    changeStatus(user) {
      this.userService
        .updateStatus(user)
        .then((data) => {
          this.$msgSuccess(data);
          this.loadLazyData();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    desabilitarUsuario(user) {
      this.userService
        .desabilitarUsuario(user)
        .then((data) => {
          this.$msgSuccess(data);
          this.loadLazyData();
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    loadLazyData() {
      this.loading = true;

      this.userService.searching(this.lazyParams).then((data) => {
        this.customers = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
      });
    },
    buscarTodosEstabelecimentos() {
      this.estabelecimentoService.findAll().then((data) => {
        this.estabelecimentos = data;
      });
    },
    showConfirmAtualizarStatusUsuarios(id) {
      this.$confirm.require({
        message: `Deseja atualizar o status de todos os usuários? (Exceto Administradores)`,
        header: "Atualizar o status dos usuários?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.checked = !this.checked;
          this.userService
            .atualizarUsuarios(id, this.checked)
            .then((data) => {
              this.$msgSuccess(data);
              this.loadLazyData();
            })
            .catch((error) => {
              this.$msgErro(error);
            });
        },
      });
      this.loading = false;
    },
    atualizarStatusEmLote() {
      let selectedIds = this.selectedUsuarios.map((item) => item.id);
      this.userService
        .updateStatusForLote(selectedIds)
        .then((data) => {
          if (data.status === 204) {
            this.$toast.add({
              severity: "success",
              summary: "Alerta!",
              detail: "Registros atualizados com sucesso.",
              life: 4000,
            });

            this.loadLazyData();
            this.selectedUsuarios = 0;
          }
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    onPage(event) {
      this.lazyParams.first = event.originalEvent.first;
      this.lazyParams.page = event.originalEvent.page;
      this.lazyParams.size = event.rows;
      this.loadLazyData();
    },
    // onSort(event) {
    //   this.lazyParams = event;
    //   this.loadLazyData();
    // },
    cleanAll() {
      this.lazyParams = {
        nome: "",
        email: "",
        estabelecimentos: [],
        first: 0,
        size: this.$refs.dt.rows,
        sortField: null,
        sortOrder: null,
      };
      this.customers = [];
      this.totalRecords = 0;
      this.selectedUsuarios = [];
      this.loading = false;
      this.loadLazyData();
    },
    // onFilter() {
    //   this.lazyParams.nome = this.filters.nome.value;
    //   this.lazyParams.email = this.filters.email.value;
    //   this.loadLazyData();
    // },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../App.scss");
</style>
